import $ from 'jquery';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';

let ScrollTriggerInit = gsap.registerPlugin( ScrollTrigger );

if( $( '.hero' ).length ) {
  gsap.to( $( '.logo' ), {
    top: 0,
    duration: 0.27,
    delay: 0.27
  } )

  gsap.fromTo ( $( '.hero-background-image' ), { yPercent: 0, scale: 1.2 }, {
    yPercent: -5,
    scale: 1.2,
    scrollTrigger: {
      trigger: $( '.hero' ),
      start: 'top top',
      scrub: 2
    } 
  } )
}

if( $( '.intro-content' ).length ) {
  ScrollTrigger.create({
    trigger: $( '.intro-content' ),
    start: 'top center',
    onEnter: () => {
      $( '.intro-content h1' ).addClass( 'headline-anim' );
      $( '.intro-content .description-container' ).addClass( 'height-anim' );
      $( '.intro-content .description-container p' ).addClass( 'text-slide-right' );
    }
  })
}


if( $( '.main-content' ).length ) {
  ScrollTrigger.create({
    trigger: $( '.main-content' ),
    start: 'top center',
    onEnter: () => $( '.main-content' ).addClass( 'open' )
  })

  gsap.to( $( '.oil-well-image' ), {
    y: -50,
    scrollTrigger: {
      trigger: $( '.oil-well-image' ),
      start: 'top center',
      scrub: 2
    }
  } )
}